<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        On planet X431A, the isotopes of carbon,
        <chemical-isotope symbol="C" />
        , have the following natural abundances:
      </p>
      <v-simple-table style="width: 380px" class="pl-8 mb-2">
        <thead>
          <tr>
            <th style="vertical-align: middle; font-size: 14px">
              <stemble-latex content="$\text{Isotope}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 14px">
              <stemble-latex content="$\text{Abundance  (\%)}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 14px">
              <stemble-latex content="$\text{Mass (amu)}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="vertical-align: middle">
              <chemical-isotope symbol="C" mass-number="12" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <number-value :value="abundance1" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <latex-number number="12.000" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle">
              <chemical-isotope symbol="C" mass-number="13" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <number-value :value="abundance2" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <latex-number number="13.003" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle">
              <chemical-isotope symbol="C" mass-number="14" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <latex-number :number="abundance3" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <latex-number number="14.003" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p>Determine the atomic weight of carbon on planet X431A.</p>
      <calculation-input
        v-model="input1"
        prepend-text="$\text{Atomic Mass:}$"
        append-text="$\text{amu}$"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalIsotope from '../displayers/ChemicalIsotope';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question5a',
  components: {
    LatexNumber,
    ChemicalIsotope,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: ['input1'],
      input1: null,
    };
  },
  computed: {
    abundance1() {
      return this.taskState.getValueBySymbol('abundance1').content;
    },
    abundance2() {
      return this.taskState.getValueBySymbol('abundance2').content;
    },
    abundance3() {
      return (100 - this.abundance1.toFloat() - this.abundance2.toFloat()).toFixed(2);
    },
  },
};
</script>
